import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import mediaContent from "../../../../pagesSections/automation/AvailableFeatures/mediaContent.module.scss"
import styles from "../../../../pagesSections/automation/AvailableFeatures/styles.module.scss"
import { pagesLinks } from "../../../_V2/common/links"

export default {
	features: [
		{
			desktopContentWidth: 490,
			tabName: "Автономность",
			desktopTitle: "Автономность и офлайн-режим",
			desktopContent: (
				<p className={mediaContent.smallDescription}>
					Система автоматизации фастфуда Quick Resto автономна и работает без интернета и электричества.
					Будь уверен — все продажи и списания будут корректно учтены, когда появится связь с сервером.
				</p>
			),
			detailLink: pagesLinks.index,
			mediaContent: (
				<StaticImage
					className={mediaContent.image1}
					src="../../../../pagesSections/automation/AvailableFeatures/assets/automation-available-1.png"
					alt="касса для фастфуда"
					breakpoints={[440, 740, 920, 580]}
					sizes="(max-width: 500px) 440px, (max-width: 800px) 740px, (max-width: 1319px) 920px, (min-width: 1320px) 580px"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
			backdropContent: <div className={styles.feature1Backdrop} />,
		},
		{
			desktopContentWidth: 490,
			tabName: "Компактность",
			desktopTitle: "Скромные габариты",
			desktopContent:
				"В небольшой точке общепита не хватает места? С Quick Resto для автоматизации фастфуда не требуется отдельная комната для сервера и большая стойка для установки кассы. Для начала работы нужен только терминал на планшете для обслуживания гостей и необходимый по закону фискальный регистратор.",
			detailLink: pagesLinks.index,
			mediaContent: (
				<StaticImage
					className={mediaContent.image2}
					src="../../../../pagesSections/automation/AvailableFeatures/assets/automation-available-2.png"
					alt="система учёта для фастфуда"
					objectFit="cover"
					objectPosition="center 80%"
					placeholder="blurred"
					quality={90}
				/>
			),
		},
		{
			desktopContentWidth: 490,
			tabName: "Мультиплатформенность",
			desktopTitle: (
				<span className={mediaContent.longWordTitle}>
					Мульти-платформенность
				</span>
			),
			desktopContent:
				"Программу для фастфуда Quick Resto можно установить на устройства с различными операционными системами: кассовый терминал работает на планшетах iOS, ОС Windows и Android, а также на терминалах Эвотор.",
			detailLink: pagesLinks.index,
			mediaContent: (
				<StaticImage
					className={mediaContent.image3}
					src="../../../../pagesSections/automation/AvailableFeatures/assets/automation-available-3.png"
					alt="программа для фастфуда"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
			backdropContent: <div className={styles.feature3Backdrop} />,
		},
	],
}
