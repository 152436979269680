import React from "react"
import classNames from "classnames"

import FeaturesGallery, {
	FEATURE_GALLERY_DIRECTION,
} from "../../../components/_V2/FeaturesGallery"

import styles from "./styles.module.scss"
import data from "./data"

interface Props {
	className?: string
}

export default function InterfaceFeatures(props: Props) {
	return (
		<section className={classNames(styles.section, props.className)}>
			<FeaturesGallery
				theme={"white"}
				className={styles.featuresGallery}
				features={data.features}
			/>
		</section>
	)
}
