import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import mediaContent from "./mediaContent.module.scss"
import { pagesLinks } from "../../../pages-data/_V2/common/links"

export default {
	features: [
		{
			desktopContentWidth: 490,
			tabName: "Для гостей",
			desktopTitle: "Экран покупателя",
			desktopContent: (
				<p className={mediaContent.smallDescription}>
					Когда расчёт происходит на кассе в момент заказа, экран покупателя помогает гостю контролировать свой
					заказ. Гость видит, какие позиции и по какой цене добавляются в чек. В результате повышается доверие,
					а вероятность недопонимания снижается. В режиме «свободной кассы» экран покупателя может работать как
					дополнительная рекламная площадь, транслируя необходимую информацию.
				</p>
			),
			detailLink:
				"/support/quick_resto_kassa/rabota_s_ustroystvami/qrdisplay_connect/",
			mediaContent: (
				<StaticImage
					className={mediaContent.image}
					src="./assets/fastfood-modes-1.png"
					alt="экран покупателя в фастфуде"
					breakpoints={[440, 740, 920, 568]}
					sizes="(max-width: 500px) 440px, (max-width: 800px) 740px, (max-width: 1319px) 920px, (min-width: 1320px) 568px"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
		},
		{
			desktopContentWidth: 490,
			tabName: "Режимы",
			desktopTitle: "Режим работы Quick Food",
			desktopContent: (
				<p className={mediaContent.smallDescription}>
					Специально разработанный интерфейс кассового терминала для
					автоматизации фастфуда и подобных форматов, режим работы “заказ-чек”.
					Никаких лишних действий, всё ради ускорения процесса обслуживания.
					Режим Quick Food действительно способен разгрузить очереди — от выбора
					блюда до приёма оплаты всего пара кликов.
				</p>
			),
			detailLink: pagesLinks.terminal,
			mediaContent: (
				<StaticImage
					className={mediaContent.image}
					src="./assets/fastfood-modes-2.png"
					alt="кассовый терминал в фастфуде"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
		},
		{
			desktopContentWidth: 490,
			tabName: "Типы оплат",
			desktopTitle: "Приём различных типов оплат",
			desktopContent: (
				<p className={mediaContent.smallDescription}>
					Оплата наличными или картой в несколько действий и последующий корректный учёт — то, чего так не
					хватало малым форматам бизнеса и то, что предлагает программа Quick Resto.
				</p>
			),
			detailLink: pagesLinks.terminal,
			mediaContent: (
				<StaticImage
					className={mediaContent.image}
					src="./assets/fastfood-modes-3.png"
					alt="кассовая система для фастфуда"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
		},
	],
}
