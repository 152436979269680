import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import styles from "./styles.module.scss"
import loyaltyStyles from "../common/loyalty-feature/styles.module.scss"
import indicatorsStyles from "../common/indicators-feature/styles.module.scss"
import implementationStyles from "../common/implementation-feature/styles.module.scss"

import { pagesLinks } from "../../common/links"

export default [
	{
		title: "Облачная программа автоматизации для фастфуда",
		description: (
			<>
				<p>
					Управление продажами в фастфуде и отслеживание финансовых результатов теперь не п
					росто удобно. С Quick Resto это ещё и современно, и технологично.

				</p>
				<p>
					Единая экосистема объединяет в себе интуитивно понятный кассовый терминал на планшете,
					мобильное приложение с функциями предзаказа и push-уведомлений, личный кабинет с полной
					статистикой продаж и аналитикой за прошедшие периоды, систему электронной очереди и QR-меню.

				</p>
			</>
		),

		img: (
			<StaticImage
				className={styles.image1}
				src="./assets/fastfood-feature-1.png"
				alt="программа автоматизации фастфуда"
				breakpoints={[440, 740, 920, 568]}
				sizes="(max-width: 500px) 440px, (max-width: 800px) 740px, (max-width: 1319px) 920px, (min-width: 1320px) 568px"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),

		reverse: true,
		className: styles.feature1,
		theme: "white",
	},
	{
		title: "Отложенные заказы",
		description: (
			<p>
				Удобный инструмент автоматизации фастфуда уменьшит очередь на кассе. Переходи к обработке следующего заказа
				без закрытия текущего и потери информации о нём. Когда гость вернётся для расчёта или дополнительного
				заказа, ты легко продолжишь работу.
			</p>
		),

		img: (
			<StaticImage
				className={styles.image2}
				src="./assets/fastfood-feature-2.png"
				alt="отложенные заказы на кассе"
				breakpoints={[440, 740, 920, 568]}
				sizes="(max-width: 500px) 440px, (max-width: 800px) 740px, (max-width: 1319px) 920px, (min-width: 1320px) 568px"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),

		className: styles.feature2,
		theme: "white",
	},
	{
		title: "Удобная CRM система управления фастфудом",
		description: (
			<p>
				Расширяй возможности бизнеса с дополнительными приложениями и оборудованием.
				Можно подключить тикет-принтер для кухни, дисплей повара и дисплей покупателя,
				а также экран электронной очереди.
			</p>
		),

		img: (
			<StaticImage
				className={styles.image3}
				src="./assets/fastfood-feature-3.png"
				alt="программа управления фастфудом"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),

		reverse: true,
		className: styles.feature3,
		theme: "white",
	},
	{
		title: "Программа лояльности, как инструмент для привлечения гостей",
		description: (
			<p>
				Quick Resto поддержит акции, бонусы, абонементы, купоны, спецпредложения
				и другие программы лояльности. Мы бесплатно разработаем мобильное
				приложение для вашей точки фастфуда, и ты сможешь предложить гостям доставку блюд и
				предзаказ с онлайн-оплатой.
			</p>
		),

		img: (
			<StaticImage
				className={loyaltyStyles.image}
				src="../common/loyalty-feature/loyalty-feature.png"
				alt="программы лояльности для фастфуда"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),

		reverse: true,

		className: loyaltyStyles.feature,
		theme: "white",

		actionText: "Подробнее",
		actionLink: pagesLinks.marketing.href,
	},
	{
		title: "Показатели всегда под рукой",
		description: (
			<p className={styles.smallDescription}>
				Используй мобильное приложение Quick&nbsp;Resto Manager для быстрого
				удаленного доступа к статистике работы заведения. Сможешь оценить
				эффективность программ лояльности, работу сотрудников, прибыльность блюд
				и многое другое.
			</p>
		),

		img: (
			<StaticImage
				className={indicatorsStyles.image}
				src="../common/indicators-feature/indicators-feature.png"
				alt="quick resto manager"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),

		className: indicatorsStyles.feature,
		theme: "white",

		actionText: "Подробнее",
		actionLink: pagesLinks.indexQRM.href,
	},
	{
		title: "Внедрение автоматизации фастфуда под ключ",
		description: (
			<>
				<p>От первой продажи до масштабирования.</p>
				<p>Quick Resto даст все инструменты:</p>
				<ul>
					<li>Приложения для управления и продаж</li>
					<li>Отчёты по продажам, блюдам, сотрудникам</li>
					<li>Складской учёт и технологические карты</li>
					<li>CRM-система и аналитика</li>
					<li>Сервис: установка, настройка, обучение</li>
					<li>Круглосуточная техподдержка</li>
				</ul>
			</>
		),

		img: (
			<StaticImage
				className={implementationStyles.image}
				src="../common/implementation-feature/implementation-feature.png"
				alt="Алексей Гаврилов"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),

		className: implementationStyles.feature,
		theme: "white",
		backdrop: (
			<div className={implementationStyles.featureTopRightTrapezeBackdrop} />
		),
	},
]
