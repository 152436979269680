import React from 'react'

export const getSeoText = () => (
  <div>
    <p>Автоматизация фастфуда: Ускорение процессов, повышение эффективности</p>

    Quick Resto – решения для автоматизации, которое станет надежным помощником в управлении вашим фастфудом.
    Система автоматизации фастфуда Quick Resto позволит вам не только упростить процессы, но и значительно увеличить
    скорость обслуживания клиентов.
    <p>Быстрое обслуживание</p>

    С Quick Resto вы сможете значительно сократить время обслуживания клиентов. Удобный интерфейс программы позволит
    кассиру быстро принимать заказы и оперативно передавать их на кухню. Это позволит улучшить общее впечатление
    клиентов и повысить уровень сервиса.

    <p>Учёт продуктов</p>

    Автоматизация фастфуда включает в себя функцию учёта продуктов. Вы сможете контролировать наличие ингредиентов в
    реальном времени и своевременно заказывать дополнительные поставки. Это поможет вам избежать ситуаций, когда
    какой-то продукт заканчивается в самый неподходящий момент.

    <p>Экономия времени и ресурсов</p>

    Программа для быстрого питания Quick Resto позволит вам оптимизировать рабочие процессы и эффективно
    использовать ресурсы. Вы сможете управлять персоналом, распределять обязанности и контролировать выполнение
    задач. Это поможет снизить затраты на оплату труда и повысить общую эффективность работы фастфуда.

    <p>Сайт и мобильное приложение</p>

    Quick Resto предлагает интеграцию вашего фастфуда в онлайн-среду с помощью сайта и мобильного приложения. Это
    позволит вашим клиентам быстро и удобно делать заказы прямо из дома или с работы. Благодаря этому вы сможете
    привлечь новых клиентов и увеличить объём продаж.

    <p>Поддержка и обновления</p>

    Quick Resto предоставляет полную поддержку клиентам и регулярные обновления. Это гарантирует бесперебойную
    работу вашего фастфуда и возможность использовать самые современные технологии в своем бизнесе.
    <br />
    Выбрав Quick Resto для автоматизации фастфуда, вы сможете улучшить качество обслуживания, увеличить прибыль и
    развивать ваш бизнес.
  </div>
)
