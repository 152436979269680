import { StaticImage } from "gatsby-plugin-image"
import React from "react"

import { pagesLinks } from "../../../pages-data/_V2/common/links"
import styles from "./styles.module.scss"

export default {
	desktopTitle: (
		<span>Автоматизация фастфуда&nbsp;</span>
	),
	price: "от 1990 ₽/мес",
	subTitle: (
		<p>
			Quick Resto — функциональная программа учёта в фастфуде
			от&nbsp;990&nbsp;₽/мес.
			<br />
			Больше возможностей для привлечения гостей и повышения среднего чека.
			Приём заказов на кассе, расчёт гостей и выдача чека, контроль работы
			персонала, учёт складских операций и аналитика продаж.
		</p>
	),
	figure: (
		<StaticImage
			className={styles.image}
			src="./assets/fastfood-presentation.png"
			alt="автоматизация фастфуда"
			breakpoints={[440, 740, 920, 568]}
			sizes="(max-width: 500px) 440px, (max-width: 800px) 740px, (max-width: 1319px) 920px, (min-width: 1320px) 568px"
			objectFit="contain"
			objectPosition="center"
			placeholder="blurred"
			quality={90}
		/>
	),
	cta: pagesLinks.registration.text,
	ctaLink: pagesLinks.registration,
	desktopCtaDetails:
		"За 10 минут, без дорогого оборудования, с удобным интерфейсом. И две недели бесплатно.",
}
