import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import mediaContent from "./mediaContent.module.scss"
import { pagesLinks } from "../../../pages-data/_V2/common/links"

export default {
	features: [
		{
			desktopContentWidth: 540,
			tabName: "Интерфейс",
			desktopTitle: "Терминал, продуманный до мелочей",
			desktopContent: (
				<>
					<p>
						На кассовом терминале простая навигация и подсказки для кассиров. А
						для руководителя — возможность планировать продажи.
					</p>
					<p data-mt="small">
						Программа позволяет контролировать актуальность меню. Сделай на
						терминале папку с новинками или высокомаржинальными блюдами.
						Персонал в начале смены проверит план и будет предлагать гостям то, что выгодно продать.
						Так можно снизить потери продуктов и увеличить эффективность работы.
					</p>
				</>
			),
			detailLink: pagesLinks.terminal,
			mediaContent: (
				<StaticImage
					className={mediaContent.image1}
					src="./assets/fastfood-interface-1.png"
					alt="кассовое оборудование для фастфуда"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
		},
		{
			desktopContentWidth: 540,
			tabName: "Повару",
			desktopTitle: "Кухонный экран повара",
			desktopContent:
				"Благодаря автоматизации фастфуда у поваров на кухне есть свой терминал — Kitchen Display System для обратной связи с кассиром. Повар может оставить комментарий к заказу, если что-то пошло не так. И отметить готовность блюда — кассир увидит новый статус заказа на своём кассовом терминале.",
			detailLink: pagesLinks.indexKDS,
			mediaContent: (
				<StaticImage
					className={mediaContent.image2}
					src="./assets/fastfood-interface-2.png"
					alt="кухонный экран повара"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
		},
		{
			desktopContentWidth: 504,
			tabName: "Покупателям",
			desktopTitle: "Экран электронной очереди",
			desktopContent:
				"Большой поток гостей больше не проблема. Размести экран электронной очереди в зоне выдачи, и гости всегда будут в курсе статуса приготовления их заказа.",
			detailLink: pagesLinks.line,
			mediaContent: (
				<StaticImage
					className={mediaContent.image3}
					src="./assets/fastfood-interface-3.png"
					alt="экран электронной очереди"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
		},
	],
}
